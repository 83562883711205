jQuery(document).ready(function ($) {
  /* ==================== Fonts ==================== */
  const WebFont = require("webfontloader");

  WebFont.load({
    google: {
      families: ["Poppins:300,400,600,700,900", ""],
    },
  });

  /* ==================== Menus ==================== */

  const mobileMenuToggle = document.querySelector(".navToggle"),
    mobileMenu = document.getElementById("mobileNav"),
    header = document.querySelector("#header"),
    subMenuItems = document.querySelectorAll(".menu-item-has-children"),
    mobileSubMenuItems = document.querySelectorAll(
      "#mobileNav .menu-item-has-children"
    ),
    adminBar = document.getElementById("wpadminbar");

  mobileSubMenuItems.forEach((element) => {
    element.addEventListener("click", toggleSubMenu);
  });

  function toggleSubMenu() {
    this.classList.toggle("active");
  }

  //Accessibility for focus, open submenu
  mobileChildren = document.querySelectorAll(".sub-menu-wrap a");
  mobileChildren.forEach((element) => {
    element.addEventListener("focus", toggleSubMenu2);
    element.addEventListener("focusout", removeSubMenu2);
  });
  function toggleSubMenu2() {
    // Close any other open submenus
    document.querySelectorAll('.menu-item-has-children.active').forEach((item) => {
      if (item !== this.closest('.menu-item-has-children')) {
        item.classList.remove('active');
      }
    });
    this.closest('.menu-item-has-children').classList.add("active");
  }
  function removeSubMenu2(event) {
    // Check if focus is moving out of the submenu
    const menuItem = this.closest('.menu-item-has-children');
    if (!menuItem.contains(event.relatedTarget)) {
      menuItem.classList.remove("active");
    }
  }

  const setHeaderHeight = () => {
    let headerOffset = header.getBoundingClientRect().bottom;
    let adminBarHeight;
    if (adminBar) {
      adminBarHeight = adminBar.offsetHeight;
      header.style.top = `${adminBarHeight}px`;
    } else {
      adminBarHeight = 0;
    }
    if (headerOffset && adminBarHeight !== null) {
      mobileMenu.style.top = `${headerOffset}px`;
      mobileMenu.style.minHeight = `calc(100vh - ${headerOffset}px)`;
    }
  };

  // Initialize header and mobile menu height
  setHeaderHeight();

  // Listen for window resizing to update header and mobile menu height as screen size changes
  addEventListener("resize", (event) => {
    setHeaderHeight();

    //Take mobile nav out of tabindex if not mobile and vice versa for desktop (on resize)
    if ($(window).width() > 991) {
      $('#mobileNav a').attr('tabindex','-1');
      $('#menu-main-menu a').removeAttr('tabindex');
    } else {
      $('#menu-main-menu a').attr('tabindex','-1');
      $('#mobileNav a').attr('tabindex','-1');
    }

  });

  mobileMenuToggle.addEventListener("click", () => {
    mobileMenuToggle.classList.toggle("is-active");
    mobileMenu.classList.toggle("menu-open");
    if  (mobileMenu.classList.contains("menu-open")) {
      $('#mobileNav a').removeAttr('tabindex');
    } else {
      $('#mobileNav a').attr('tabindex','-1');
    }
  });

  //Take mobile nav out of tabindex if not mobile and vice versa for desktop
  if ($(window).width() > 991) {
    $('#mobileNav a').attr('tabindex','-1');
    $('#menu-main-menu a').removeAttr('tabindex');
  } else {
    $('#menu-main-menu a').attr('tabindex','-1');
    $('#mobileNav a').attr('tabindex','-1');
  }


});




